import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { UsersListItem } from '../../services/users';

interface RemoveUserConfirmationDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  dataToRemove: UsersListItem;
  remove: (item: UsersListItem) => void;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: 524,
    maxWidth: '100%',
  },
  button: {
    width: 150,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  acceptButton: {
    width: 150,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  outlinedButton: {
    width: 150,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
    borderColor: theme.palette.grey[400],
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(8),
  },
  title: {
    fontFamily: 'Hero New Regular',
    fontSize: 32,
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  description: {
    fontFamily: 'Hero New Regular',
    fontSize: 16,
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
}));

const RemoveUserConfirmationDialog: React.FC<
  RemoveUserConfirmationDialogProps
> = ({
  isDialogOpened,
  onDialogClose,
  dataToRemove,
  remove,
}: RemoveUserConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();
  const onRemove = useCallback(
    (item) => {
      remove(item);
      onDialogClose();
    },
    [remove, onDialogClose]
  );

  return (
    <Dialog
      open={isDialogOpened}
      onClose={onDialogClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <Box className={classes.content}>
        <Typography className={classes.title}>
          Are you sure you want to delete this user?
        </Typography>
        <Typography className={classes.description}>
          This action is permanent, and their data cannot be recovered once
          deleted.
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={onDialogClose}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onRemove(dataToRemove);
            }}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.acceptButton }}
          >
            Yes, delete user
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RemoveUserConfirmationDialog;

import { Box, Button, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as SaveChanges } from '../../assets/icons/save-changes.svg';

interface EditContentButtonsProps {
  updating: boolean;
  handleRedirectLinkClick: () => void;
  handleSave: () => void;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
}));

const EditContentButtons: React.FC<EditContentButtonsProps> = ({
  updating,
  handleRedirectLinkClick,
  handleSave,
  onDelete,
}: EditContentButtonsProps): JSX.Element => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <Box display="flex" mt={5}>
      <Box mr={2}>
        <Button
          color="secondary"
          disabled={updating}
          variant="contained"
          disableElevation
          onClick={handleRedirectLinkClick}
          classes={{ root: classes.button }}
        >
          <SvgIcon
            component={Cancel}
            classes={{ root: classes.icon }}
          ></SvgIcon>
          Cancel
        </Button>
      </Box>
      <Box mr={2}>
        <Button
          type="submit"
          disabled={updating}
          color="secondary"
          variant="contained"
          disableElevation
          onClick={handleSave}
          classes={{ root: classes.button }}
        >
          <SvgIcon
            component={SaveChanges}
            classes={{ root: classes.icon }}
          ></SvgIcon>
          Save changes
        </Button>
      </Box>
      {location.pathname.includes('/users/') && (
        <Button
          disabled={updating}
          color="secondary"
          variant="contained"
          disableElevation
          onClick={onDelete}
          classes={{ root: classes.button }}
        >
          <DeleteIcon classes={{ root: classes.icon }} />
          Delete user
        </Button>
      )}
    </Box>
  );
};

export default EditContentButtons;
